<template>
  <TogglePanel
    :title="labelSelection"
    :icon="{
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down'
    }"
  >
    <slot name="header"></slot>
    <slot v-if="!panelDataOptions.length && !dataSelector" name="info">
      <div class="info">
        <div>{{ $t("connector_standard") }}</div>
      </div>
    </slot>
    <div class="data-selector-container">
      <div
        class="text-center"
        v-if="!dataSelector && (showAddNewDataButton || showAddAllDataButton)"
      >
        <span
          class="btn btn-xs btn-primary"
          @click="dataSelector = true"
          v-if="showAddNewDataButton"
        >
          {{ $t("add_new_data") }}
        </span>
        <span
          class="btn btn-xs btn-default"
          @click.stop.prevent="$emit('all')"
          style="margin-left: 5px"
          v-if="showAddAllDataButton"
        >
          {{ $t("titles.add_all_data") }}
        </span>
      </div>
      <template v-if="dataSelector">
        <ControlDataSelector
          v-if="referenceConnectorId"
          v-model="dataId"
          :parser="listParser"
          :connectorId="referenceConnectorId"
          :multiConnector="referenceConnectorId ? multiConnector : true"
          :exclude="dataListId"
          :connectorFilter="connectorFilter"
          :allowedTypes="['bool', 'float', 'int', 'string']"
          @connectorChanged="connectorId = $event"
        >
          <template #label>
            <div
              class="data-selector-label clicable"
              :title="$t('close')"
              @click.stop.prevent="dataSelector = false"
            >
              <label for="">{{ $t("data_source") }}</label>
              <div class="toolbar-item">
                <i class="fa fa-close" :title="$t('remove_this_item')"></i>
              </div>
            </div>
          </template>
        </ControlDataSelector>
        <ControlDataSelector
          v-else
          v-model="dataId"
          :parser="listParser"
          :exclude="dataListId"
          :connectorFilter="connectorFilter"
          :allowedTypes="['bool', 'float', 'int', 'string']"
          @connectorChanged="connectorId = $event"
        >
          <template #label>
            <div
              class="data-selector-label clicable"
              :title="$t('close')"
              @click.stop.prevent="dataSelector = false"
            >
              <label for="">{{ $t("data_source") }}</label>
              <div class="toolbar-item">
                <i class="fa fa-close" :title="$t('remove_this_item')"></i>
              </div>
            </div>
          </template>
        </ControlDataSelector>
        <div class="text-center import-options">
          <span
            class="btn btn-xs btn-primary"
            :class="dataSelectionOnly && !dataId ? 'disabled' : ''"
            @click.stop.prevent="onAddData"
          >
            {{ $tc("titles.add_selected", 1) }}
          </span>
          <span
            class="btn btn-xs btn-default"
            @click.stop.prevent="onImport"
            v-if="dataSelectionOnly && connector"
          >
            {{ $t("titles.add_all_data") }}
          </span>
        </div>
      </template>
    </div>
    <div class="data-list" v-if="panelDataOptions.length">
      <draggable v-model="panelDataOptions" handle=".handle">
        <div class="item" v-for="item in panelDataOptions" :key="item.data_id">
          <div class="toolbar-item">
            <i
              class="fa fa-trash clicable"
              :title="$t('remove_this_item')"
              @click.stop.prevent="onDelData(item.data_id)"
            ></i>
          </div>
          <div
            class="item-title clicable"
            @click.stop.prevent="onCheckData(item.data_id)"
            :title="itemTitle(item.data_id)"
          >
            <span>
              <i class="fa fa-arrows-v"></i>
              <i
                v-if="selectable"
                :class="
                  item.checked
                    ? 'fa fa-check-square-o clicable'
                    : 'fa fa-square-o clicable'
                "
                style="vertical-align: middle"
                :title="$t('titles.select_data')"
              ></i>
              <span class="handle">
                {{ item.name }}
              </span>
            </span>
          </div>
        </div>
      </draggable>
    </div>
    <slot name="footer"></slot>
  </TogglePanel>
</template>

<script>
import { isEqual } from "lodash";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import draggable from "vuedraggable";

export default {
  name: "DataListForm",
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    dataListParser: {
      type: Function,
      required: false,
      default: null
    },
    connectorFilter: {
      type: [Function],
      required: false,
      default: null
    },
    selectable: {
      type: Boolean,
      required: false,
      default: true
    },
    labelSelection: {
      type: String,
      required: false,
      default: "data_list"
    },
    labelItems: {
      type: String,
      required: false,
      default: "data_series"
    },
    dataSelectionOnly: {
      type: Boolean,
      required: false,
      default: () => true
    },
    multiConnector: {
      type: Boolean,
      required: false,
      default: true
    },
    showAddNewDataButton: {
      type: Boolean,
      required: false,
      default: true
    },
    showAddAllDataButton: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    TogglePanel,
    ControlDataSelector,
    draggable
  },
  data() {
    return {
      dataId: "",
      connectorId: "",
      items: [],
      dataSelector: false
    };
  },
  computed: {
    screenId() {
      return (this.$store.getters["dashboard/draft"] || { screenId: "" })
        .screenId;
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    dataListId() {
      return this.items.map(({ data_id }) => data_id);
    },
    panelDataOptions: {
      set(values) {
        this.$set(this, "items", values);
      },
      get() {
        return (this.items || []).map((item) => {
          var entry = this.dataList.find(({ id }) => id == item.data_id);
          if (!entry) {
            if (/connector_\d+_id/.test(item.data_id)) {
              var conn_id = item.data_id.match(/\d+/)[0];
              entry = this.connectorList.find(({ id }) => id == conn_id);
            }
          }
          return {
            ...item,
            ...{
              name: (entry && entry.name) || ""
            }
          };
        });
      }
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    referenceConnectorId() {
      return (
        (this.$store.getters["dashboard/screenRefMap"](this.screenId) || {})
          ?.conn1 || ""
      );
    },
    connector() {
      const ID = this.connectorId || this.refConnectorId || "";
      return ID ? this.connectorList.find(({ id }) => id == ID) : null;
    },
    customHistoryDataList() {
      return this?.connector?.portal_data?.custom_history_data_list || {};
    }
  },
  watch: {
    value: {
      handler(n) {
        if (isEqual(n, this.items)) return;
        this.$set(this, "items", JSON.parse(JSON.stringify(n)));
      },
      deep: true,
      immediate: true
    },
    items: {
      handler(n) {
        if (isEqual(n, this.value)) return;
        this.$emit("input", n);
      },
      deep: true
    }
  },
  methods: {
    listParser(lst) {
      return this.dataListParser ? this.dataListParser(lst) : lst;
    },
    addData(entry) {
      if (!entry.data_id) return;
      let lst = JSON.parse(JSON.stringify(this.items || []));
      if (lst.some((i) => i.data_id == entry.data_id)) return;
      lst.push(entry);
      this.$set(this, "items", lst);
    },
    onAddData() {
      if (!this.connectorId || (this.dataSelectionOnly && !this.dataId)) return;
      if (this?.dataId) {
        // remove any connector based item, since data based list was selected
        let data = this.dataList.find(({ id }) => id == this.dataId);
        this.onDelData(`connector_${data.clp_id}_id`);
      } else {
        // remove any data based item, since connector based list was selected
        this.dataList
          .filter(({ clp_id }) => clp_id == this.connectorId)
          .forEach(({ id }) => {
            this.onDelData(id);
          });
      }

      this.addData({
        data_id: this?.dataId || `connector_${this.connectorId}_id`,
        enabled: true,
        checked: true
      });
    },
    onDelData(dataId) {
      if (!dataId) return;
      let lst = JSON.parse(JSON.stringify(this.items || []));
      lst = lst.filter(({ data_id }) => data_id != dataId);
      this.$set(this, "items", lst);
    },
    onCheckData(dataId) {
      if (!this.selectable || !dataId) return;
      let lst = JSON.parse(JSON.stringify(this.items || []));
      let item = lst.find(({ data_id }) => data_id == dataId);
      item.checked = !item.checked;
      this.$set(this, "items", lst);
    },
    onImport() {
      this.listParser(this.dataList).forEach((data) => {
        this.addData({
          data_id: data.id,
          enabled: true,
          checked: this.selectable
            ? (this.customHistoryDataList?.checked || []).indexOf(
              data.reference_id
            ) >= 0
            : false
        });
      });
    },
    itemTitle(dataId) {
      let data = this.dataList.find(({ id }) => dataId == id);
      if (!data) return "";
      let conn = this.$store.getters["dashboard/connectorList"].find(
        ({ id }) => id == data.clp_id
      );
      return `#id: ${data.id} - ${this.$tc("connector", 1)}: ${(conn &&
        conn.name) ||
        this.$t("item_not_found")}`;
    }
  }
};
</script>

<style scoped>
div.option {
  margin: 3px;
  white-space: nowrap;
}

div.option > i {
  margin-right: 3px;
}

.import-options {
  padding: 0;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}

.import-options > span.btn {
  margin: 0 5px 0 0;
}

div.data-list {
  margin-top: 5px;
  padding-left: 15px;
}

div.item {
  position: relative;
  width: 100%;
  font-size: 1em;
  border-top: 1px solid #e4e4e4;
  z-index: 1;
}

div.item-title {
  padding: 4px 22px 4px 2px;
  font-weight: 600;
  white-space: normal;
  width: 100%;
}

div.toolbar-item {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 4;
}

div.toolbar-item > i {
  padding: 0 4px;
  min-width: 22px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.info {
  margin: 0 12px 0 12px;
  padding: 5px;
  height: auto;
}

.info > label {
  display: block;
  font-weight: normal;
  font-size: 12pt;
  padding-left: 2px;
}
.info > div {
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 14pt;
  text-align: center;
}

i.fa-arrows-v {
  margin-left: -10px;
  margin-right: 10px;
  color: #888;
}

.data-selector-container {
  padding: 10px;
}
.data-selector-label {
  clear: both;
  position: relative;
  margin-right: -10px;
}
</style>
