<template>
  <div class="form-group form-group-sm">
    <div class="form-inline">
      <div class="form-group title" data-testid="connector">
        <slot name="label">
          <div>
            <label for="data-connector" v-if="label" :title="title">
              {{ `${$t(label)}:` }}
            </label>
            <ResourceField
              v-if="currentData && expressionToolbar"
              :value="currentData"
              link="js"
              class="pull-right"
              ref="resource"
            >
              <template #label>
                <i class="fa fa-copy btn btn-xs bg-default"></i>
                <ToolTip
                  v-if="expressionInfo"
                  :title="expressionInfo"
                  @click="toExpression"
                  icon="fa fa-code btn btn-xs bg-default"
                >
                </ToolTip>
                <span v-else></span>
              </template>
            </ResourceField>
          </div>
        </slot>
        <select
          id="data-connector"
          class="form-control no-padding"
          v-model="connector"
          @change="dataId = null"
          :disabled="!multiConnector"
        >
          <option v-for="conn in connectorList" :value="conn.id" :key="conn.id">
            {{ conn.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="input-group">
      <div
        class="input-group-addon"
        :class="$attrs.disabled ? 'disabled' : ''"
        v-if="addon"
      >
        {{ addon }}
      </div>
      <input
        class="no padding form-control"
        :value="$t('no_data_found')"
        readonly
        v-if="connectorDataList && !connectorDataList.length"
        data-testid="no-data-found"
      />
      <v-select
        v-else
        label="name"
        class="control-data-selector-input"
        :value="dataId"
        @input="dataId = $event"
        :options="dataSelectOptions"
        :reduce="(data) => data.id"
        :selectable="
          (data) => !(data.id && data.id.includes && data.id.includes('group'))
        "
        :filterable="!loadingData"
        :loading="loadingData"
        :placeholder="placeholder"
        :title="placeholder"
        :disabled="$attrs.disabled ? true : false"
      >
        <template #option="data">
          <div
            class="option"
            title=""
            v-if="data.id.includes && data.id.includes('group')"
          >
            <span class="option-group">
              {{ $tc(data.name, 2) }}
            </span>
            <span class="option-desc" v-if="data.id.includes('device_group')">
              {{ data.device.name }}
            </span>
          </div>
          <div
            class="option"
            :title="
              typeof data.id == 'string' && data.device
                ? $tc(data.device.connector.name)
                : $tc('data')
            "
            v-else
          >
            <span class="option-name">{{ $tc(data.name) }}</span>
          </div>
        </template>
        <template #selected-option="data">
          <span
            >{{
              data.id &&
              data.id.includes &&
              data.id.includes("_") &&
              !loadingData
                ? data.id.includes("connector")
                  ? "(" + $tc("connector") + ")"
                  : "(" + $t(String((data.device || {}).name)) + ")"
                : ""
            }}
            {{
              data.id
                ? $tc(data.name)
                : loadingData
                ? $t("loading")
                : placeholder
            }}
          </span>
        </template>
        <template #no-options="{ search, searching }">
          <template v-if="searching">
            {{ `${$t("no_result_found")} ${$t("for")} ${search}` }}
          </template>
          <em style="opacity: 0.5" v-else>{{ $t("type_to_search") }}.</em>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { groupBy } from "lodash";
import { mapActions, mapGetters } from "vuex";
import DataService from "@/services/data";
import ResourceField from "@/components/registration/resource-field.vue";
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "ControlDataSelector",
  components: {
    ResourceField,
    ToolTip
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: 0
    },
    connectorId: {
      type: Number,
      required: false,
      default: null
    },
    addon: {
      type: String,
      required: false,
      default: ""
    },
    parser: {
      type: [Function],
      required: false,
      default: null
    },
    connectorFilter: {
      type: [Function],
      required: false,
      default: null
    },
    exclude: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      default: "source",
      required: false
    },
    multiConnector: {
      type: Boolean,
      required: false,
      default: true
    },
    allowedTypes: {
      type: Array,
      required: false,
      default: () => ["bool", "float", "int"]
    },
    expressionToolbar: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      dataId: null,
      filtered: false,
      connector: null,
      loadingData: false,
      loadingConnectors: false,
      dataLoadedQueue: [],
      connectorsLoadedQueue: [],
      errorOptions: [{ id: 0, name: this.$t("invalid_data") }],
      error: false,
      connectorProperty: /connector(_[a-zA-Z$]+)+/,
      connectorPropertyWidthId: /connector_(\d+)_.+/,
      isMounted: false,
      expressionInfo: ""
    };
  },
  computed: {
    title() {
      return `${this?.currentConnector?.id || ""} ${
        this?.currentConnector?.name || ""
      }`;
    },
    connectorDataList() {
      if (this.connector == null) return [];
      let lst = this.dataFrom(this.connector);
      if (lst) {
        if (this.parser && typeof this.parser == "function") {
          lst = this.parser(lst);
        }
        if (this?.exclude?.length) {
          lst = lst.filter((item) => this.exclude.indexOf(item.id) == -1);
        }
      }
      lst = this.loadingData
        ? [{ id: this.dataId, name: `${this.$t("loading")}...` }]
        : lst;
      return lst;
    },
    dataSelectOptions() {
      let lst = this.connectorDataList || [];
      return this.error ? lst.concat(this.errorOptions) : lst;
    },
    currentConnector() {
      return this.connectorList.find(({ id }) => id == this.connector);
    },
    placeholder() {
      if (this.loadingData) return "";
      let placeholder = "titles.select_data";
      if (this.dataId) {
        let data = (this.connectorDataList || []).find(
          (data) => data.id == this.dataId
        );
        if (data) {
          if (typeof this.dataId == "number")
            placeholder = data.description || data.name;
          else placeholder = data.name;
        }
      }
      return this.$tc(placeholder);
    },
    connectorList() {
      let lst = this.rawConnectorList?.concat?.(
        this.loadingConnectors
          ? { id: 0, name: this.$t("loading") + "..." }
          : []
      );
      if (this.connectorFilter) {
        lst = this.connectorFilter(lst);
      }
      return lst;
    },
    currentData() {
      return (
        (this.dataId &&
          this.connectorDataList.find(
            ({ id }) => parseInt(id) == parseInt(this.dataId)
          )) ||
        null
      );
    },
    ...mapGetters("dashboard", {
      rawConnectorList: "connectorList",
      extendedProperties: "extendedProperties",
      hasResourceFrom: "hasResourceFrom"
    }),
    ...mapGetters("user", { contractId: "contract_id" })
  },
  watch: {
    value: {
      immediate: true,
      handler(n) {
        this.flush();
        if (
          !n &&
          this.connector &&
          this.connectorId &&
          this.connectorId != this.connector
        ) {
          // does not change the selected connector
        } else {
          this.connector = this.connectorId ?? this.localStorageConnector();
        }
        const validateAction = () => this.validateDataId(n);
        if (!this.hasResourceFrom("data", this.connector)) {
          this.dataLoadedQueue.push(validateAction);
        } else {
          this.$nextTick(validateAction);
        }
      }
    },
    dataId(val) {
      this.error = false;
      // check if dataId is a connector property
      // from model ("connector_<id>_<property>" format)
      let result = val?.match?.(this.connectorPropertyWidthId);
      if (result && this.currentConnector?.base_model) {
        // if it is, remove the connectorId source
        // in the string ("connector_<property>" format)
        val = val.replace(result[1] + "_", "");
      }
      this.$nextTick(() => {
        this.$emit("input", val, this.connector);
        const info = this?.$refs?.resource?.info || null;
        if (info && val) {
          this.expressionInfo = this.$t("hints.use_in_expression", {
            item: `<span style="white-space:nowrap;"><span style="color:orange;">${info.js}</span></span>`
          });
        } else {
          this.expressionInfo = "";
        }
      });
    },
    connector: {
      immediate: true,
      async handler(val) {
        this.localStorageConnector(
          val ??
            (this.$store.getters["dashboard/dashboardEquipmentId"] ||
              this.rawConnectorList?.[0]?.id ||
              null)
        );
        if (val && !this.hasResourceFrom("data", val)) {
          this.fetchDataList();
        }
        this.$emit("connectorChanged", val);
      }
    },
    loadingData(val) {
      if (!val) {
        this.dataLoadedQueue.forEach((action) => action());
        this.dataLoadedQueue = [];
      }
    },
    loadingConnectors(val) {
      if (!val) {
        this.connectorsLoadedQueue.forEach((action) => action());
        this.connectorsLoadedQueue = [];
      }
    }
  },
  methods: {
    validateDataId(n) {
      const findConnectorFor = async (dataId) => {
        // tries to find the source connector of the data
        let connector = await this.getConnectorFromData(this.dataId);
        if (connector != null) {
          // once found, it updates current connector locally and
          // in the control (if the saved one ins't correct)
          this.connector = connector;
          if (this.connectorId != this.connector) {
            this.$emit("input", dataId, this.connector);
          }
        } else if (dataId) {
          // shows error message if data is invalid
          this.errorOptions[0].id = dataId;
          this.error = true;
        }
      };
      // check if dataId is a connector property
      // from model ("connector_<property>" format)
      let result = n?.match?.(this.connectorProperty);
      if (result) {
        // if it is, insert the connectorId source
        // in the string to be able to find it in the list
        // ("connector_<id>_<property>" format)
        if (this.connectorId || this.connector) {
          this.dataId = n.replace(
            "_",
            "_" + (this.connectorId || this.connector) + "_"
          );
        } else {
          this.dataId = n;
          return;
        }
      } else {
        if (this.connector && this.$utils.isUUID(n)) {
          let data = (this.dataListFrom(this.connector) || [])?.find(
            ({ reference_id }) => reference_id == n
          );
          if (data) {
            this.dataId = data.id;
          } else {
            this.dataId = n;
          }
        } else {
          this.dataId = n;
        }
      }
      // check if the data is from the current selected connector
      let listHasData =
        this.connectorDataList &&
        this.connectorDataList.some((data) => data.id == this.dataId);
      if (this.hasResourceFrom("connector", null) && !listHasData) {
        findConnectorFor(n);
      } else if (!this.hasResourceFrom("connector", null)) {
        this.connectorsLoadedQueue.push(() => findConnectorFor(n));
      }
    },
    fetchDataList() {
      let connectorId = this.connector;
      this.loadingData = true;
      this.fetchResourcesFrom({
        resource: "data",
        connectorId,
        update: "device"
      }).then(() => {
        this.loadingData = false;
      });
    },
    async getConnectorFromData(id) {
      let connector = this.connectorList?.find?.(({ id: connId }) =>
        this.dataFrom(connId)?.some?.((data) => data.id == id)
      );
      if (connector) {
        return connector.id;
      } else if (!isNaN(parseInt(id))) {
        let service = new DataService();
        let data = await service.get(
          id,
          this.$store.getters["user/contract_id"]
        );
        if (data && typeof data == "object") {
          return data.device.connector.id;
        }
      }
      return null;
    },
    fetchConnectors() {
      return this.fetchResourcesFrom({ resource: "connector" });
    },
    dataListFrom(connectorId) {
      let devNames = {};
      let l = [];
      let dataList = (
        this.$store.getters["dashboard/dataListFrom"](connectorId) || []
      ).filter(({ type }) => !type || this.allowedTypes.indexOf(type) >= 0);
      let devices = groupBy(dataList, (i) => {
        devNames[i.device.name] = i.device.id;
        return i.device.id;
      });
      let keys = Object.keys(devNames).sort();
      keys.forEach((name) => {
        let devId = devNames[name];
        // only display the device name if there are more than one
        // if (keys.length > 1) {
        l.push({
          id: `data_group_${devId}`,
          name: `${name}`
        });
        // }
        l = l.concat(
          devices[devId].sort((a, b) =>
            new Intl.Collator(this.$i18n.locale, {
              sensitivity: "base",
              numeric: true
            }).compare(a.name.trim(), b.name.trim())
          )
        );
      });
      return l;
    },
    dataFrom(connId) {
      return (this.dataListFrom(connId) || []).concat(
        this.extendedProperties.filter(
          ({ device }) =>
            device.connector.id == connId || device.connector.id == -1
        )
      );
    },
    flush() {
      this.connectorsLoadedQueue = [];
      this.dataLoadedQueue = [];
    },
    localStorageConnector(connectorId) {
      if (connectorId) {
        this.$store.dispatch(
          "dashboard/setControlDataSelectorSource",
          connectorId
        );
      }
      return (
        connectorId ||
        this.$store.getters["dashboard/controlDataSelectorSource"]
      );
    },
    toExpression() {
      if (this?.$refs?.resource?.info?.js) {
        this.$emit("setExpression", this?.$refs?.resource?.info?.js);
      }
    },
    ...mapActions("dashboard", ["fetchResourcesFrom"])
  },
  created() {
    if (!this.hasResourceFrom("connector", null)) {
      this.loadingConnectors = true;
      this.fetchConnectors().then(() => {
        this.loadingConnectors = false;
        if (this.connector == null) {
          this.connector =
            this.$store.getters["dashboard/dashboardEquipmentId"] ||
            (this.rawConnectorList?.[0]?.id ?? null);
        }
      });
    }
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style scoped>
.form-control {
  width: 100%;
}
.form-control.invalid {
  border-color: red;
}

.title {
  font-size: 10pt;
  color: #646464;
  font-weight: 600;
  width: 100%;
}

.not-allowed {
  color: gray;
}
.not-allowed:hover {
  cursor: not-allowed;
}

div.input-group {
  width: 100%;
  background-color: #fff;
}
.input-group-addon.disabled {
  background-color: #eee;
}
</style>
<style lang="scss">
.control-data-selector-input {
  &.vs--loading .vs__clear {
    margin-right: 0;
  }

  .vs__spinner {
    width: 4em;
    height: 4em;
  }

  .vs__dropdown-toggle {
    height: 30px;
    overflow: hidden;

    .vs__selected {
      word-break: break-all;
      line-height: 1em;
      font-size: 90%;
      padding: 0;
    }
  }

  .vs__dropdown-option--disabled {
    color: black;
  }

  .option {
    font-size: 90%;
    margin-left: -0.9em;

    .option-desc {
      margin-left: -0.3em;
      font-weight: 600;
      display: block;
      color: #666;
    }

    .option-group {
      font-size: 1em;
      font-weight: bold;
      margin-left: -0.5em;
      color: #444;
    }
  }
}
</style>
